// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-static-customer-service-js": () => import("./../../../src/templates/static/customer-service.js" /* webpackChunkName: "component---src-templates-static-customer-service-js" */),
  "component---src-templates-static-index-js": () => import("./../../../src/templates/static/index.js" /* webpackChunkName: "component---src-templates-static-index-js" */),
  "component---src-templates-static-not-found-js": () => import("./../../../src/templates/static/not-found.js" /* webpackChunkName: "component---src-templates-static-not-found-js" */)
}

